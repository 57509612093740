import {
  reqSetIsShowExploreModal,
  reqSetIsShowExpandAmenity,
  reqSetIsShowAmenityVirtualTour,
  actSetExploreModal,
  actSetDetailModal,
  reqSetDetailModal,
  reqSetExploreModal,
} from "../../reduxs/explore-modal/action";
import { reqSetIsShowGalleryModal } from "../../reduxs/district-future-detail/action";
import React, { useCallback, useEffect } from "react";
import socket from "../../helper/socket";
import VirtualModal from "./virtual-modal";
import { getS3BEMediaUrl } from "../../helper/media";
import { useDispatch, useSelector } from "react-redux";
import { reqSetActiveAreaId } from "../../reduxs/home/action";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reqFilterUnitPrecinct } from "../../reduxs/unit-explore/action";
import { setSelectedHotspot } from "../3d-scene/CanvasBox";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right-thin-black.svg";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import {
  PAGES_ENUM,
  FEATURES_ENUM,
  COMPONENTS_ENUM,
} from "../../constants/modules";
import {
  ACTION_NAME,
  REACTUI_PAGES,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import { reqSetIsShowPrecinctExploreDetail } from "../../reduxs/precinct-explore/action";
import classNames from "classnames";

const RightPanel = (props) => {
  const { reactuiPage, isPresentation } = props;
  const isShowRightPanel = useSelector(
    (state) => state.exploreModal.isShowExploreModal
  );
  const isShowExpandAmenity = useSelector(
    (state) => state.exploreModal.isShowExpandAmenity
  );

  const modal = useSelector((state) => state.exploreModal.modal);
  const dispatch = useDispatch();
  const isShowGallery = useSelector(
    (state) => state.districtdetail.isShowGalleryModal
  );
  const isShowAmenityVirtualTour = useSelector(
    (state) => state.exploreModal.isShowAmenityVirtualTour
  );
  const pages = useSelector((state) => state.configuration.pages);

  const isShowVirtualTourBtn =
    modal?.virtualTour && !!modal?.virtualTour?.medias?.length;

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_CLOSE_MODAL) {
      onClose();
    }
    if (content.action === ACTION_NAME.SHOW_AMENITY_MODAL_IMAGE) {
      handleShowModalImage();
    }
    if (content.action === ACTION_NAME.CLOSE_AMENITY_MODAL_IMAGE) {
      handleCloseModalImage();
    }
    if (content.action === ACTION_NAME.SHOW_AMENITY_VIRTUAL_TOUR) {
      handleClickVirtualTour();
    }
    if (content.action === ACTION_NAME.CLICK_AMENITY_ITEM) {
      dispatch(reqSetExploreModal(content?.data?.amenity?.modal.id));
      content?.data?.amenity && dispatch(reqSetIsShowExploreModal(true));
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [modal]);

  const onClose = () => {
    setSelectedHotspot();

    dispatch(reqSetActivePrecinctID());
    dispatch(reqSetIsShowExploreModal(false));
  };

  const handleButtonInContent = (event) => {
    const clickedEl = event.target;
    if (clickedEl.id == "btn_close_right_panel") {
      dispatch(reqSetIsShowGalleryModal(false));
      return onClose();
    }

    if (clickedEl.id == "btn_explore_precinct") {
      if (clickedEl.dataset?.area_id) {
        dispatch(reqSetActiveAreaId(clickedEl.dataset.area_id));
      } else {
        dispatch(reqSetActiveAreaId());
      }

      if (clickedEl.dataset?.precinct) {
        dispatch(reqFilterUnitPrecinct(clickedEl.dataset.precinct));
      } else {
        dispatch(reqFilterUnitPrecinct());
      }

      dispatch(reqSetIsShowPrecinctExploreDetail(true));
      onClose();
    }
  };

  const renderModalContent = useCallback(() => {
    const VALID_REACTUI_PAGES = [
      REACTUI_PAGES.AMENITIES_PAGE,
      REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE,
    ];

    const visibleComponents = pages
      ?.find((p) => p.name === PAGES_ENUM.INTERACTIVE_3D && !!p?.props?.visible)
      ?.modules?.find(
        (m) =>
          VALID_REACTUI_PAGES.includes(m?.props?.reactui_page) &&
          reactuiPage === m?.props?.reactui_page &&
          !!m?.props?.visible
      )
      ?.features?.find(
        (f) =>
          ((reactuiPage === REACTUI_PAGES.AMENITIES_PAGE &&
            f.name === FEATURES_ENUM.RIGHT_PANEL) ||
            (reactuiPage === REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE &&
              f.name === FEATURES_ENUM.TRANSPORT_RIGHT_PANEL)) &&
          !!f?.props?.visible
      )
      ?.components?.filter((c) => !!c?.props?.visible);

    if (!visibleComponents?.length) return <></>;

    const isVisible = (type) => {
      switch (type) {
        case "image":
          return reactuiPage === REACTUI_PAGES.AMENITIES_PAGE
            ? visibleComponents?.some(
                (c) => c.name === COMPONENTS_ENUM.AMENITY_IMAGE
              )
            : reactuiPage === REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE
            ? visibleComponents?.some(
                (c) => c.name === COMPONENTS_ENUM.TRANSPORT_IMAGE
              )
            : false;
        case "name":
          return reactuiPage === REACTUI_PAGES.AMENITIES_PAGE
            ? visibleComponents?.some(
                (c) => c.name === COMPONENTS_ENUM.AMENITY_NAME
              )
            : reactuiPage === REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE
            ? visibleComponents?.some(
                (c) => c.name === COMPONENTS_ENUM.TRANSPORT_NAME
              )
            : false;
        case "description":
          return reactuiPage === REACTUI_PAGES.AMENITIES_PAGE
            ? visibleComponents?.some(
                (c) => c.name === COMPONENTS_ENUM.AMENITY_DESCRIPTION
              )
            : reactuiPage === REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE
            ? visibleComponents?.some(
                (c) => c.name === COMPONENTS_ENUM.TRANSPORT_DESCRIPTION
              )
            : false;
        case "virtual_tour_link":
          return reactuiPage === REACTUI_PAGES.AMENITIES_PAGE
            ? visibleComponents?.some(
                (c) => c.name === COMPONENTS_ENUM.VIRTUAL_TOUR_LINK
              )
            : reactuiPage === REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE
            ? visibleComponents?.some(
                (c) => c.name === COMPONENTS_ENUM.TRANSPORT_VIRTUAL_TOUR_LINK
              )
            : false;
      }
    };

    return (
      <>
        <div className="h-full w-[285px] float-right border-none bg-brand relative pointer-events-[all]">
          {isVisible("image") && modal?.image?.path && (
            <>
              <div className="absolute top-[127px] right-[15px] cursor-pointer z-[1000] pointer-events-auto">
                <img
                  src={"/icons/expand-image.svg"}
                  alt="modal-expand-image"
                  onClick={handleShowModalImage}
                />
              </div>
              <div className="pointer-events-auto">
                <img
                  src={getS3BEMediaUrl(modal?.image?.path)}
                  alt="modal-image"
                  className="w-full h-[177px] object-cover"
                />
              </div>
            </>
          )}
          <div className="pointer-events-auto absolute z-[9999] px-9 mt-7 max-h-[calc(100%_-_177px_-_145px)] overflow-hidden overflow-y-scroll">
            {isVisible("name") && (
              <div className="text-panelHeader !leading-[110%] text-primary  uppercase mb-[30px]">
                {modal?.name}
              </div>
            )}
            {isVisible("description") && (
              <div className="text-p !font-[400] !leading-[150%] text-primary whitespace-pre-line">
                {modal?.description}
              </div>
            )}
          </div>
        </div>
        {isVisible("virtual_tour_link") && isShowVirtualTourBtn && (
          <div
            onClick={handleClickVirtualTour}
            className="bg-brand text-primary absolute bottom-9 right-9 px-[22.5px] py-0 z-[1000] h-[60px] w-[13rem] flex items-center justify-between text-p !leading-[30px] uppercase cursor-pointer !pointer-events-auto"
            style={{
              border: "1px solid",
              display: modal?.virtualTour ? "flex" : "none",
            }}
          >
            Virtual tour
            <RightArrowIcon className="h-fit !fill-[var(--primary-color)] *:!fill-[var(--primary-color)]" />
          </div>
        )}
      </>
    );
  }, [modal, pages, reactuiPage]);

  const handleShowModalImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_AMENITY_MODAL_IMAGE);
    }
    dispatch(reqSetIsShowExpandAmenity(true));
  };

  const handleCloseModalImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_AMENITY_MODAL_IMAGE);
    }
    dispatch(reqSetIsShowExpandAmenity(false));
  };

  const handleClickWrapper = (clickedId) => {
    if (clickedId === "id-wrapper-right-panel") {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_CLOSE_MODAL);
      }
      return onClose();
    }
  };

  const handleClickVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_AMENITY_VIRTUAL_TOUR);
    }
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqSetIsShowAmenityVirtualTour(true));
  };

  return (
    <>
      <TransitionGroup>
        {isShowRightPanel && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div
              id="id-wrapper-right-panel"
              className="absolute w-full h-full bg-transparent top-0 pointer-events-none"
              onClick={(e) => {
                e.stopPropagation();
                const clickedId = e.target.id;
                handleClickWrapper(clickedId);
              }}
            >
              <div
                className={classNames(
                  `absolute right-0 top-0 bottom-0 mt-auto mb-auto z-[100] w-[285px] h-[calc(100svh_-_122px_-_122px)]`,
                  reactuiPage === REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE
                    ? "h-[calc(100vh_-_260px)]"
                    : ""
                )}
                onClick={(e) => handleButtonInContent(e)}
              >
                {renderModalContent()}
              </div>
            </div>
          </CSSTransition>
        )}
        {isShowAmenityVirtualTour && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div>
              <VirtualModal isPresentation={isPresentation} />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {isShowExpandAmenity && (
        <div className={"media-popup-gallery fixed inset-0 bg-brand z-[9999]"}>
          <div className={"popup-gallery-wrapper h-full w-full relative"}>
            <div
              className="popup-gallery-close absolute top-12 right-10 cursor-pointer z-[1002] rounded-full bg-black/30 p-3"
              onClick={handleCloseModalImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 22"
                fill="none"
              >
                <path
                  d="M1 1L25 22.8182M25 1L1 22.8182"
                  stroke="white"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                />
              </svg>
            </div>
            <div className="container-image flex justify-center items-center w-full h-full bg-transparent">
              <img
                alt={"modal-image"}
                src={getS3BEMediaUrl(modal?.image?.path)}
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RightPanel;
