/** @format */

import socket from "../../helper/socket";
import React, { useEffect, useRef, useState } from "react";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { getS3BEMediaUrl } from "../../helper/media";
import { cn } from "../../helper/utils";
import { useSelector } from "react-redux";
import { PAGES_ENUM } from "../../constants/modules";
import { Editor } from "../editor/Editor";

const RIGHT = "right-image";
const MULTI_RIGHT = "multi-right-image";
const MULTI = "multiple-image";
const IMAGE = "image";

const Neighborhood = (props) => {
  const { isPresentation } = props;
  const pageRef = useRef(null);
  const contentStaticType1 = useSelector(
    (state) => state.gallery.contentStaticType1
  );
  const pages = useSelector((state) => state.configuration.pages);
  const loading = useSelector((state) => state.configuration.loading);
  const [contents, setContents] = useState([]);
  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollPercentage *
          (pageRef.current.scrollHeight - pageRef.current.clientHeight);
      }
    }
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  useEffect(() => {
    if (pages && !loading) {
      pages.map((items) => {
        if (items.name === PAGES_ENUM.STATIC_PAGE) {
          items.modules?.map((i) => {
            if (
              i?.props?.type === "template-1" &&
              contentStaticType1.path === i?.props?.path
            ) {
              setContents(i?.props.sections);
            }
          });
        }
      });
    }
  }, [pages, loading, contentStaticType1]);

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollTo(0, 0);
    }
  }, [contentStaticType1.path]);
  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  };

  return (
    <div
      ref={pageRef}
      onScroll={onScroll}
      className={cn(
        "neighborhood-page overflow-x-hidden disable-scroll-bar w-screen absolute top-0 mt-[49px] h-[calc(100dvh-49px)] bg-brand mb-[60px] overflow-y-scroll",
        {
          "h-full mt-0": isPresentation,
        }
      )}
    >
      <div className="w-full mb-[47px] pb-[70px]">
        <div className="w-full flex items-center flex-col">
          <div className="w-full flex items-center flex-col bg-brand-secondary">
            <div className="flex items-start relative px-[209px] py-[114px] w-[1366px] gap-[10px] flex-col bg-brand-secondary">
              <div className="flex flex-col w-[521px] items-start">
                <div className="flex flex-col w-[948px] items-start gap-[50px]">
                  <div className="webkit-amenities-text-stroke text-primary text-hero whitespace-break-spaces max-w-[600px] uppercase leading-[44px] ">
                    {contents[0]?.title}
                  </div>
                  <div className="w-[500px] text-primary text-p opacity-60 self-end !font-normal">
                    {contents[0]?.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full flex items-center flex-col bg-brand">
          {contents?.map((info, index) => {
            if (index === 0) {
              return null;
            }

            if (info.type === MULTI) {
              return (
                <div
                  className={cn("relative w-[1366px] h-[1283px]")}
                  key={info?.tag}
                >
                  <div
                    className={cn(
                      "absolute top-[114px] w-[948px] flex flex-col items-start gap-[50px] left-[50%] transform translate-x-[-50%]"
                    )}
                  >
                    <span className="flex items-center gap-[14px] text-primary justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="9"
                        viewBox="0 0 8 9"
                        fill="none"
                        className="fill-current text-primary"
                      >
                        <circle cx="4" cy="4.5" r="4" />
                      </svg>
                      <span className="text-panelSubHeader">{info.tag}</span>
                    </span>
                    <span className="text-primary uppercase text-hero whitespace-break-spaces">
                      {info.title}
                    </span>
                  </div>
                  <span className="absolute whitespace-break-spaces top-[543px] left-[828px] w-[450px] flex gap-[12px] text-p text-primary !leading-[21px] !font-normal">
                    {typeof info.description === "string" ? (
                      info.description
                    ) : (
                      <Editor
                        defaultValue={info.description}
                        readOnly={true}
                      />
                    )}
                  </span>
                  {info?.media1 && (
                    <img
                      className="absolute top-[543px] left-[88px] w-[600px] h-[626px] object-cover"
                      src={getS3BEMediaUrl(info?.media1?.path)}
                      alt={`media1`}
                    />
                  )}
                  {info?.media2 && (
                    <>
                      <img
                        className="absolute top-[813px] left-[828px] w-[375px] h-[470px] z-10 object-cover object-bottom"
                        src={getS3BEMediaUrl(info?.media2?.path)}
                        alt={`media2`}
                      />
                      <div className="w-[375px] h-[470px] top-[784px] left-[889px] absolute bg-brand-secondary" />
                    </>
                  )}
                </div>
              );
            }
            if (info.type === IMAGE) {
              return (
                <div
                  className={cn(
                    "flex flex-col w-[1366px] h-[1283px] pl-[209px] pt-[114px] "
                  )}
                  key={info?.tag}
                >
                  <div
                    className={cn(
                      "w-[948px] flex flex-col items-start gap-[50px] "
                    )}
                  >
                    <span className="flex items-center gap-[14px] text-primary justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="9"
                        viewBox="0 0 8 9"
                        fill="none"
                        className="fill-current text-primary"
                      >
                        <circle cx="4" cy="4.5" r="4" />
                      </svg>
                      <span className="text-panelSubHeader">{info.tag}</span>
                    </span>
                    <span className="text-primary text-hero whitespace-break-spaces">
                      {info.title}
                    </span>
                    <span className="w-[500px] whitespace-break-spaces self-end flex text-p text-primary !leading-[21px] !font-normal">
                      {typeof info.description === "string" ? (
                        info.description
                      ) : (
                        <Editor
                          defaultValue={info.description}
                          readOnly={true}
                        />
                      )}
                    </span>
                  </div>
                  {info?.media && (
                    <img
                      className="w-[1068px] h-[626px] mt-[90px] object-cover object-[0_16%]"
                      src={getS3BEMediaUrl(info?.media?.path)}
                      alt={`media`}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
      {!isPresentation && <div className="bottom-top" />}
    </div>
  );
};

export default Neighborhood;
