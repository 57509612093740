import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import settingModuleApi from "../../../../apis/api/page-configuration";
import galleryApi from "../../../../apis/api/gallery";
import {
  refetchGetFeatureList,
  reqDeleteModule,
  reqGetFeatureList,
  reqGetPageList,
} from "../../../../reduxs/page-configuration/action";
import { cn } from "../../../../helper/utils";
import Table from "../components/Table";
import useColumn from "./useColumn";
import Switch from "../components/switch";
import { Modal, Button } from "../../components/commons";
import TrashIcon from "../components/svgs/TrashIcon";
import { toast } from "react-toastify";
import closeIcon from "../../../../assets/images/close-white.svg";
import {
  reqGetGallery,
  reqOnchangePlaylistOfGallery,
} from "../../../../reduxs/gallery/action";
import pageConfiguration from "../../../../apis/api/page-configuration";

const UpsertGalleryPlaylist = React.lazy(() =>
  import("../../gallery/upsertGalleryPlaylist")
);

const TableConfiguration = ({ moduleState, setModuleState, pageState }) => {
  const dispatch = useDispatch();
  const features = useSelector((state) => state.configuration.features);
  const isLoading = useSelector((state) => state.configuration.featureLoading);
  const selectedCanvasProfileId = useSelector(
    (state) => state.cms.selectedCanvasProfileId
  );
  const canvasProfiles = useSelector((state) => state.cms.canvasProfiles);
  const selectedCanvasProfile = useMemo(() => {
    const profile = canvasProfiles?.find(
      (t) => t.id === selectedCanvasProfileId
    );
    return profile || canvasProfiles?.[0] || null;
  }, [selectedCanvasProfileId, canvasProfiles]);

  const [galleryState, setGalleryState] = React.useState(null);
  const [isLinkPlaylistModal, setIsLinkPlaylistModal] = React.useState(false);

  const isBGalleyModule = pageState?.label?.includes("Gallery Page Type B");

  const { columns } = useColumn({
    features,
    pageState,
    moduleState,
    setGalleryState,
    openLinkPlaylistModal: () => setIsLinkPlaylistModal(true),
  });

  const dataSource = features?.length
    ? _.flatMap(features, (feature) =>
        feature.components
          ?.sort((a, b) => a?.props?.order - b?.props?.order)
          ?.map((component) => ({
            ...component,
            nameFeature: feature.name,
            idModule: moduleState?.id,
          }))
      )?.reduce((acc, curr) => {
        if (
          curr.props?.group &&
          acc.some((item) => item.props?.group === curr.props.group)
        ) {
          return acc;
        }
        return acc.concat(curr);
      }, [])
    : [];

  useEffect(() => {
    if (moduleState?.id) {
      dispatch(reqGetFeatureList({ idModule: moduleState?.id }));
    }
  }, [moduleState?.id]);

  const toggleVisibleModule = async (visible) => {
    try {
      const res = await settingModuleApi.updateModules(moduleState.id, {
        props: {
          visible,
        },
      });

      if (res.data) {
        await Promise.all([
          dispatch(reqGetPageList({})),
          dispatch(reqGetFeatureList({ idModule: moduleState?.id })),
        ]);

        setModuleState({
          ...moduleState,
          visible,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePlaylist = useCallback(
    (option) => {
      const { id } = option;

      if (id === galleryState?.playlistId) return;

      dispatch(
        reqOnchangePlaylistOfGallery({
          isUpdate: true,
          galleryId: galleryState?.galleryId,
          playlistId: id,
          onSuccess: async () => {
            await pageConfiguration.updateComponent(galleryState?.galleryId, {
              props: {
                playlistId: id,
                namePlaylist: option?.name,
              },
            });
            dispatch(reqGetGallery(galleryState?.galleryId));
            dispatch(refetchGetFeatureList({ idModule: moduleState?.id }));
            setGalleryState({ ...galleryState, playlistId: id });
          },
        })
      );
    },
    [galleryState, moduleState?.id]
  );

  const setModuleLayer = async ({ value }) => {
    try {
      const res = await settingModuleApi.updateModules(moduleState.id, {
        props: {
          layer: value,
        },
      });
      if (res.data) {
        dispatch(reqGetPageList({}))
        setModuleState({
          ...moduleState,
          props: {
            ...moduleState.props,
            layer: value
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  };
  const layerOptions = useMemo(() => selectedCanvasProfile.layers?.map(num => ({
    label: `Layer ${num}`, value: num
  })).concat({
    label: `Layer 0`, value: 0
  }), [selectedCanvasProfile]);

  return (
    <div className="mx-auto max-w-[1000px] 2xl:max-w-[1200px]">
      <div className="flex my-4 justify-between items-center">
        <div className="flex gap-4 items-center">
          <h2>{moduleState?.label}</h2>
          <Switch
            onChange={(e) => toggleVisibleModule(e.target.checked)}
            defaultChecked={moduleState?.visible}
            checked={moduleState?.visible}
            value={moduleState?.value}
            id={`${moduleState?.id}`}
          />
        </div>
        <div>
          <div className="flex gap-2">
            <div className="flex items-center font-normal">Layer: </div>
            <Select
              value={layerOptions?.find(i => i.value === moduleState.props.layer) || { label: 'Please select', value: null}}
              className="w-[200px] cursor-pointer z-[100]"
              options={layerOptions}
              onChange={(e) => setModuleLayer(e)}
            />
          </div>
        </div>
        {isBGalleyModule && (
          <Modal
            maskClassName="bg-opacity-50"
            zIndex="z-[9998]"
            triggerClassName="text-red-500"
            trigger={
              <Button className="text-red-500 border-red-500" variant="text">
                <TrashIcon />
              </Button>
            }
            content={({ setIsShow }) => (
              <div>
                <h2 className="text-center text-lg pb-3 font-medium">
                  Are you sure you want to delete this module ?
                </h2>
                <div className="flex gap-4 justify-center">
                  <Button variant="text" onClick={() => setIsShow(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        reqDeleteModule(moduleState?.id, () => {
                          for (let gallery of dataSource) {
                            (async () =>
                              await galleryApi.deleteGallery(gallery?.id))();
                          }
                          setIsShow(false);
                          toast.success("Delete successfully");
                          setModuleState(null);
                        })
                      );
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            )}
          />
        )}
      </div>
      <div className="relative w-full h-full">
        <Table
          manualPagination
          loading={isLoading}
          cellClassName="first:bg-gray-200 first:text-black border-l-[1px] border-stone-200"
          wrapperClassName={cn(
            "rounded-md mt-1 bg-white overflow-y-auto h-[60vh] 2xl:h-[65vh] border-[1px] border-gray-300",
            {
              "h-fit":
                !!dataSource?.length && dataSource?.length <= 5 && !isLoading,
            }
          )}
          classnamethead="sticky top-0 z-10 bg-white"
          data={dataSource || []}
          columns={columns}
        />
      </div>
      <Modal
        iconCancelMark={() => (
          <div className="z-[9999] cursor-pointer fixed top-[50px] right-[30px]">
            <img src={closeIcon} alt="close-icon" />
          </div>
        )}
        show={isLinkPlaylistModal}
        setShow={setIsLinkPlaylistModal}
        panelClassName="w-[80vw] p-0 rounded-none bg-[rgb(251,251,251)]"
        zIndex="z-[9998]"
        maskClassName="opacity-90"
        content={() => (
          <React.Suspense fallback={<div> Loading... </div>}>
            <UpsertGalleryPlaylist
              playlistId={galleryState?.playlistId}
              gallery={{
                galleryId: galleryState?.galleryId,
              }}
              mediaType={galleryState?.type}
              callbackOnchangePlaylist={onChangePlaylist}
            />
          </React.Suspense>
        )}
      />
    </div>
  );
};

export default TableConfiguration;
