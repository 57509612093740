import React, { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import Quill from "quill";
import { useController } from "react-hook-form";

// Editor is an uncontrolled React component
export const Editor = forwardRef(
  (
    { readOnly, defaultValue, onTextChange, onSelectionChange, controller },
    ref
  ) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement("div")
      );
      const quill = new Quill(editorContainer, {
        theme: "snow",
        readOnly,
        modules: {
          toolbar: readOnly ? false : ["bold", "italic", "underline", "strike"],
        },
        formats: ["bold", "italic", "underline", "strike"],
      });

      ref && (ref.current = quill);

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(quill.getContents());
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = []
        delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            ops.push({
              insert: op.insert
            })
          }
        })
        delta.ops = ops
        return delta
      })

      return () => {
        ref && (ref.current = null);
        container.innerHTML = "";
      };
    }, [ref]);

    return <div className="w-full" ref={containerRef}></div>;
  }
);

Editor.displayName = "Editor";
